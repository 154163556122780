<template>
  <v-container fluid class="py-0">
    <v-form>
      <div v-for="(question, index) in data.questions" :key="index">
        <p>
          {{ index + 1 }}. {{ $languageProcessor.translate(question.title) }}
        </p>
        <div v-if="question.questionType == 'rating'">
          <v-input :value="question.answer">
            <v-rating large v-model="question.answer"> </v-rating>
          </v-input>
        </div>
      </div>
      <v-btn @click="submit">ok</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { ReviewDataService } from "../../services/DataServiceiReview";

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {
      data: [],
    };
  },

  mounted() {
    console.log(`123${JSON.stringify(this.$route.params.segmentId)}`)
    this.getQuestions();
  },

  methods: {
    getQuestions() {
      reviewDataService.getQuestions().then((res) => {
        this.data = res.questions;
      });
    },
    submit() {

      let dataToSend = {
          segmentId: this.$route.params.data.segmentId,
          categoryId: this.$route.params.data.categoryId,
          subCategoryId: this.$route.params.data.subCategoryId,
          brandId: this.$route.params.data.brandId,
          ...this.questionProcessor(this.data.questions)
        }
        console.log(JSON.stringify(dataToSend))
        reviewDataService.saveQuestionData(dataToSend)
    },



    questionProcessor(questions) {
        let processedData = {
            answers: []
        }
      questions.forEach((q) => {
        if (q.questionType == 'rating') {
            processedData.answers.push({
                title: this.$languageProcessor.getEnText(q.title),
                answer: q.answer
            })
        }
      });
      return processedData
    },
  },
};
</script>